import React from 'react';
import { Link } from 'react-router-dom';

export default function DigiSerives() {
  return (
    <section className="digi-services pt-50">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center mb-5">
              {/* <div>
                <span className="span-arrow">Best Digital Agency</span>
                <img src="assets/img/arro-right.svg" alt="arrow" />
              </div> */}
              <h2>
                We have extensive experience in social
                <span className="text-orange"> media marketing</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0" style={{height:"350px"}}>
              <img src="assets/img/icons/d-1.svg" alt="icon" />
              <h3 className="h5">Social Media</h3>
              <p>
              We develop tailored strategies that align with your business goals and target audience preferences.
              </p>
              {/* <Link
                to="/services"
                className="read-more-link text-decoration-none"
              >
                Explore More <i className="far fa-arrow-right ms-2"></i>
              </Link> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0" style={{height:"350px"}}>
              <img src="assets/img/icons/d-2.svg" alt="icon" />
              <h3 className="h5">Content Creation</h3>
              <p>
              Our creative team will produce high-quality content that resonates with your audience and encourages interaction.
              </p>
              {/* <Link
                to="/services"
                className="read-more-link text-decoration-none"
              >
                Explore More <i className="far fa-arrow-right ms-2"></i>
              </Link> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0" style={{height:"350px"}}>
              <img src="assets/img/icons/d-3.svg" alt="icon" />
              <h3 className="h5">Community Management</h3>
              <p>
              We'll actively manage your social media communities, responding to comments, messages, and mentions promptly.
              </p>
              {/* <Link
                to="/services"
                className="read-more-link text-decoration-none"
              >
                Explore More <i className="far fa-arrow-right ms-2"></i>
              </Link> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xl-3">
            <div className="digi-service-single mb-4 mb-xl-0" style={{height:"350px"}}>
              <img src="assets/img/icons/d-4.svg" alt="icon" />
              <h3 className="h5">Analytics and Insights</h3>
              <p>
              We track and analyze your social media performance, providing valuable insights to optimize your campaigns.
              </p>
              {/* <Link
                to="/services"
                className="read-more-link text-decoration-none"
              >
                Explore More <i className="far fa-arrow-right ms-2"></i>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
