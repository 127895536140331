import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
SwiperCore.use([Navigation]);

const CryptoTestimonial = () => {
  const swiperOption = {
    slidesPerView: 2,
    speed: 700,
    spaceBetween: 30,
    slidesPerGroup: 2,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },

      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },

      991: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  };
  return (
    <section className="crypto-testimonial  bg-dark-black pt-60 pb-120">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="section-title mb-5">
              <h2 className="text-white">What they say about us</h2>
              <p className="text-white">
              Discover what our clients have to say about their experience with us. Their success stories inspire us to keep delivering exceptional service..
              </p>
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-12">
            <div className="text-left text-lg-end mb-5 mb-lg-0">
              <Link to="/about-us" className="btn rounded-pill btn-primary">
                All Feedback
              </Link>
            </div>
          </div> */}
        </div>
        <div className="row">
          <Swiper {...swiperOption}>
            <SwiperSlide>
              <div className="crypto-single-testimonial bg-soft-black">
                <ul className="review-rate mb-0 list-unstyled list-inline">
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                </ul>
                <blockquote className="m-0">
                  <p className="m-0">
                    “SenerTech has been an invaluable partner in helping us navigate the complex world of IT. Their team of experts provided us with insightful consultations, tailored solutions, and ongoing support. Thanks to their guidance, we've been able to streamline our operations and improve our overall efficiency.”
                  </p>
                </blockquote>
                <div className="author-meta d-flex align-items-center">
                  {/* <div className="pe-3">
                    <img
                      src="assets/img/testimonial/app-testimonial-3.png"
                      alt="Author"
                    />
                  </div> */}
                  <div>
                    <h5 className="fw-normal text-white h6 m-0">
                    Sumit Goswami
                    </h5>
                    {/* <span>Feb 19, 2022</span> */}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default CryptoTestimonial;
