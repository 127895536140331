import React from "react";
import { Link } from "react-router-dom";
import {
  navServicesLinks,
  navProductLinks,
} from "./../../data";

const OffCanvasMenuWms = () => {
  return (
    <div className="offcanvas-body">
      <ul className="nav col-12 col-md-auto justify-content-center main-menu">
        <li className="nav-item dropdown" aria-label="Close">
          <Link legacyBehavior to="/wms">
            <a className="nav-link">Home</a>
          </Link>
        </li>
        <li data-bs-dismiss="offcanvas" aria-label="Close">
          <Link legacyBehavior 
        //   to="/about-us"
          >
            <a className="nav-link">Features</a>
          </Link>
        </li>
        <li data-bs-dismiss="offcanvas" aria-label="Close">
          <Link legacyBehavior 
        //   to="/about-us"
          >
            <a className="nav-link">Pricing</a>
          </Link>
        </li>
        <li data-bs-dismiss="offcanvas" aria-label="Close">
          <Link legacyBehavior 
        //   to="/about-us"
          >
            <a className="nav-link">Request Demo</a>
          </Link>
        </li>
       
      </ul>
      <div className="action-btns mt-4 ps-3">
        <span data-bs-dismiss="offcanvas" aria-label="Close">
          <Link legacyBehavior 
        //   to="/request-demo"
          >
            <a className="btn btn-primary">Sign In</a>
          </Link>
        </span>
      </div>
    </div>
  );
};

export default OffCanvasMenuWms;
