import React from 'react';
import { Link } from 'react-router-dom';
import {Modal} from "react-bootstrap"


const Terms = (
    {
        show,
        onHide
    }
) => {
  return (
    <div>
    <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={onHide}
        size="xl"
    >
        <Modal.Header closeButton>
            <Modal.Title id="check-representative">Terms</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='p-2'>
        <p>This Privacy Policy describes the policies of Senertech Software Private Limited, use and disclosure of your information that we collect when you use our website (https://www.senertech.in) herein after called the “website”. By accessing or using the website, you are consenting to the collection, use and disclosure of your information in accordance with this Privacy Policy. If you do not consent to the same, please do not access or use the website.
        </p>
        <p>We may modify this Privacy Policy at any time without any prior notice to you and will post the revised Privacy Policy on the website. The revised policy will be effective 180 days from when the revised policy is posted in the website and your continued access or use of the website after such time will constitute your acceptance of the revised Privacy Policy. We therefore recommend that you periodically review this page.
        </p>
        <h5 >INFORMATION WE COLLECT</h5>
        <p>We will collect and process the following personal information about you:</p>
        <ul >
            <li className='text-black'>Name</li>
            <li className='text-black'>Email</li>
            <li className='text-black'>Mobile</li>
        </ul>
        </div>
        </Modal.Body>
    </Modal>
</div>
  )
};

export default Terms;
