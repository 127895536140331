import React, { Component } from 'react';
import NavbarAiims from '../../layout/Header/Navbar_Aiims'
import Layout from '../../layout/Layout';
import HeroOne from './AiimsOne';
import SoftwareCompanyFeedback from "../../components/softwareCompanyHome/SoftwareCompanyFeedback"
import FeatureImgContentFour from '../../components/feature-img-content/FeatureImgContentFour';
import PageMeta from '../../components/common/PageMeta';
import FooterSocial from '../../layout/Footer/FooterSocial';
import FeatureOne from '../../components/features/FeatureOne';

class AiimsSassOne extends Component {
  render() {
    return (
      <Layout>
        <PageMeta title='Aiims' />
        <NavbarAiims navDark />
        <HeroOne />
        <FeatureOne />
        <FeatureImgContentFour />
        <SoftwareCompanyFeedback 
       ifForQuote = {true}/>
        <FooterSocial />
      </Layout>
    );
  }
}

export default AiimsSassOne;
