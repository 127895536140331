import React from 'react';
import { Link } from 'react-router-dom';

const FooterSocial = () => {
  return (
    <>
      <footer className='footer-section'>
        {/* <!--footer bottom start--> */}
        <div className='footer-bottom bg-dark text-white py-4'>
          <div className='container'>
            <div className='row '>
              {/* <div className='col-md-12 col-lg-12 d-flex justify-content-around '> */}
              
                <div className='col-md-2 text-center'>
                <a  // href="/request-demo"
                  // className='me-5'
                >
                  Terms of Use
                </a>
                </div>
                <div className='col-md-2 text-center'>
                <a  // href="/request-demo"
                  // className='me-5'
                >
                  Privacy Policy
                </a>
                </div>
                <div className='col-md-2 text-center'>
                <a  // href="/request-demo"
                  // className='me-5'

                >
                  Refund Policy
                </a>
                </div>
                <div className='col-md-2 text-center'>
                <a  // href="/request-demo"
                  // className='me-5'
                >
                  Disclaimer
                </a>
                </div>
                <div className='col-md-3 text-center'>
                <a  // href="/request-demo"
                  // className='me-5'
                >
                  Knowledgebase (Dynamic CMS)
                </a>
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
        {/* <!--footer bottom end--> */}
      </footer>
    </>
  );
};

export default FooterSocial;
