import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const CorporateAgencyFeedback = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);
  const feedbackData = [
    {
      id: 1,
      quote:
        "“SenerTech has been an invaluable partner in helping us navigate the complex world of IT. Their team of experts provided us with insightful consultations, tailored solutions, and ongoing support. Thanks to their guidance, we've been able to streamline our operations and improve our overall efficiency.” ",
      userImage: "assets/img/feedback-user-1.png",
      userName: "[Sumit Goswami], [Pune]",
      userTitle: "Satisfied IT Consultant Client",
    },
    {
      id: 2,
      quote:
        "“We were struggling to increase our online visibility until we partnered with SenerTech. Their digital marketing strategies have been incredibly effective. Our website traffic has skyrocketed, and we've seen a significant boost in leads and sales. I highly recommend them!” ",
      userImage: "assets/img/feedback-user-2.png",
      userName: "[Shivam Chaudhary], [Jaipur]",
      userTitle: "Happy Digital Marketing Client",
    },
    {
      id: 3,
      quote:
        "“SenerTech has taken our social media presence to the next level. Their team is always up-to-date on the latest trends and knows how to create engaging content that resonates with our target audience. We've seen a significant increase in engagement and brand awareness thanks to their expertise.”",
      userImage: "assets/img/feedback-user-3.png",
      userName: "[Karan Chaurasia], [Techify]",
      userTitle: "Delighted Social Media Management Client",
    },
    {
      id: 4,
      quote:
        "We needed professional videos to showcase our products, and SenerTech delivered beyond our expectations. Their team was creative, efficient, and easy to work with. The final videos were of exceptional quality and helped us attract new customers.”" ,
      userImage: "assets/img/feedback-user-4.png",
      userName: "[Radha Mathur]",
      userTitle: "Thrilled Video Production Client",
    },
    {
      id: 5,
      quote:
        "“SenerTech's graphic design team has a knack for creating visually stunning designs that capture the essence of our brand. They've helped us revamp our website, create marketing materials, and develop a consistent brand identity. We're extremely satisfied with their work.” ",
      userImage: "assets/img/feedback-user-5.png",
      userName: "[Anjali Sharma]",
      userTitle: "Impressed Graphic Design Client",
    },
    
    // Add more feedback objects as needed
  ];

  return (
    <>
      {isClient && (
        <div className="sections p-50">
          <div className="pb-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-7 col-xxl-6">
                  <h2 className="text-center mb-0 heading-text">
                  Exceptional Client Praise Behind the Scenes
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid p-0">
            <div className="row g-0">
              <div className="col-12">
                <Swiper
                  modules={[Navigation, Autoplay]}
                  autoplay={{ delay: 3000 }}
                  loop={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1280: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1536: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                  className="feedback-slider"
                >
                  {feedbackData.map((feedback) => (
                    <SwiperSlide key={feedback.id} >
                      <div className="card border-0" style ={{height:"380px"}}>
                        <div className="card-body feedback-slider__card">
                        <div >
                          <h5>{feedback.userTitle}</h5>
                          <p className="feedback-slider__details">
                            {feedback.quote}
                          </p>
                          </div>
                            <h5 className="mb-0 mt-2 heading-text text-center ">
                              {feedback.userName}
                            </h5>
                          </div>
                        </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CorporateAgencyFeedback;
