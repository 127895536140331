import { Link } from "react-router-dom";

const MarketplaceAgencyDarkCard = () => {
  const services = [
    {
      id: 1,
      title: "Comprehensive Strategies",
      description:
        "We create integrated digital marketing strategies that encompass SEO, content marketing, social media, email campaigns, and paid advertising to maximize your online presence. ",
    },
    {
      id: 2,
      title: "Targeted Audience Engagement",
      description:
        "Our data-driven approach helps identify and engage your target audience, ensuring your message resonates and drives meaningful interactions. ",
    },
    {
      id: 3,
      title: "Analytics and Insights",
      description:
        "We leverage advanced analytics to track campaign performance, providing actionable insights that inform future strategies and optimize ROI.",
    },
    {
      id: 4,
      title: "Brand Development",
      description:
        "Our digital marketing services focus on building a strong brand identity online, enhancing recognition, trust, and loyalty among your audience.",
    },
    {
      id: 5,
      title: "Continuous Optimization",
      description:
        " We continually assess and refine your marketing efforts to adapt to changing trends and consumer behaviors, ensuring sustained growth and engagement. ",
    },
    {
      id: 6,
      title: "Results Driven Approach",
      description:
        "Our focus is on delivering results that drive business growth. By combining comprehensive strategies, brand development, we ensure that your efforts gives measurable outcomes.",
    },
  ];

  return (
    <>
      <section className="ma-bg-2 pt-50 pb-50 position-relative z-1">
        <img
          src="assets/img/home_37/dark-card-shape.png"
          alt="Background shape"
          className="ma-dark-card-bg img-fluid h-100 position-absolute top-0 end-0 pe-none z-n1"
        />
        <div className="container">
          <div className="row align-items-center justify-content-between g-4">
            <div className="col-lg-7 col-xl-6">
              <div className="d-inline-block px-4 py-1 rounded-pill border border-warning mb-3">
                <p className="ma-warning-text fw-bold mb-0">What we do</p>
              </div>
              <h2 className="ail-title text-white fs-48 fw-600">
                What we create, what you can enjoy
              </h2>
            </div>
            <div className="col-lg-5 col-xl-4">
              <p className="text-white mb-0">
              By choosing Senertech for your digital marketing needs, you're investing in a partnership that will drive your business forward and help you achieve your online goals.
              </p>
            </div>
          </div>
          <div className="mt-40">
            <div className="row g-4">
              {services.map((service) => (
                <div key={service.id} className="col-md-6 col-xl-4" >
                  <div className="ma-dark-card bg-white bg-opacity-10 px-4 py-4 rounded trans" style={{height:"300px"}}>
                    <h6 className="ma-warning-text fs-16 fw-bold mb-2">
                      0{service.id}
                    </h6>
                    <h5 className="text-white fs-20 fw-semibold mb-3">
                      {service.title}
                    </h5>
                    <p className="text-white mb-0">{service.description}</p>
                    {/* <div className="ma-warning-gd ch-1 my-4"></div> */}
                    {/* <Link
                      href="/marketplace-agency"
                      className="text-white fw-medium mb-0 d-flex align-items-center gap-3"
                    >
                      Explore More
                      <span className="ma-sm-btn bg-white rounded-circle fs-12">
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </Link> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MarketplaceAgencyDarkCard;
