import { Link } from "react-router-dom";

const CorporateAgencyAchievement = () => {
  return (
    <section className="pt-50">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-left text-lg-center mb-32 mb-lg-0">
              <img src="assets/img/about.jpg" alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-right">
              <h4 className="text-primary h5 mb-3">Why Choose Us</h4>
              <h2 className="mb-4">
                We are working with <br />
                15 years exprience
              </h2>
              <p>
                By choosing Senertech for your IT consultation needs, you're investing in a partnership that will drive your business forward and help you stay ahead of the competition.
              </p>
              <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                <li className="py-1">
                  <div className="d-flex about-icon-box align-items-center">
                    <div className="me-3">
                      <img src="assets/img/pie-chart.png" alt="" />
                    </div>
                    <div>
                      <h5>Expert around the world</h5>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="d-flex about-icon-box align-items-center">
                    <div className="me-3">
                      <img src="assets/img/team.png" alt="" />
                    </div>
                    <div>
                      <h5>Best practice for business</h5>
                    </div>
                  </div>
                </li>
              </ul>

              <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>
                  Deep Industry Expertise
                </li>
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>
                  Customized Solutions
                </li>
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>
                  Cost-Effective Solutions
                </li>
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>
                  Proven Track Record
                </li>
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>Data Security and Privacy
                </li>
                <li className="py-1">
                  <i className="fad fa-check-circle me-2 text-primary"></i>
                  Proactive Support 
                </li>
              </ul>
              {/* <Link
                to="/about-us"
                className="link-with-icon text-decoration-none mt-3 btn btn-primary"
              >
                Learn More
                <i className="fas fa-arrow-right"></i>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  // return (

  //   <>
  //     <div className="achievement-section">
  //       <div className="achievement-section__heading">
  //         <div className="container">
  //           <div className="row g-4 align-items-center">
  //             <div className="col-lg-12 col-xl-12">
  //               <h2 className="mb-0 heading-text text-capitalize">
  //               Senertech is your trusted partner in navigating the complex landscape of technology
  //               </h2>
  //             </div>
  //             {/* <div className="col-lg-4 col-xl-5">
  //               <div className="text-lg-end">
  //                 <Link href="/corporate-agency" className="btn btn-primary">
  //                   About Company
  //                 </Link>
  //               </div>
  //             </div> */}
  //             <div className="w-100"></div>
  //             <div className="col-lg-4 col-xl-5">
  //               <img
  //                 src="assets/img/avatar.png"
  //                 alt="image"
  //                 className="img-fluid"
  //               />
  //             </div>
  //             <div className="col-lg-8 col-xl-7">
  //               <p className="mb-0">
  //               By choosing Senertech for your IT consultation needs, you're investing in a partnership that will drive your business forward and help you stay ahead of the competition.
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="container">
  //         <div className="row g-4 g-xxl-5 justify-content-center">
  //           <div className="col-sm-6 col-md-4 col-lg-3">
  //             <div className="achievement-circle text-center">
  //               <div className="mb-4">
  //                 <img
  //                   src="assets/img/achievement-img-1.png"
  //                   alt="image"
  //                   className="img-fluid"
  //                 />
  //               </div>
  //               <span className="achievement-circle__title heading-text">
  //               Deep Industry Expertise
  //               </span>
  //             </div>
  //           </div>
  //           <div className="col-sm-6 col-md-4 col-lg-3">
  //             <div className="achievement-circle text-center">
  //               <div className="mb-4">
  //                 <img
  //                   src="assets/img/achievement-img-2.png"
  //                   alt="image"
  //                   className="img-fluid"
  //                 />
  //               </div>
  //               <span className="achievement-circle__title heading-text">
  //               Customized Solutions
  //               </span>
  //             </div>
  //           </div>
  //           <div className="col-sm-6 col-md-4 col-lg-3">
  //             <div className="achievement-circle text-center">
  //               <div className="mb-4">
  //                 <img
  //                   src="assets/img/achievement-img-3.png"
  //                   alt="image"
  //                   className="img-fluid"
  //                 />
  //               </div>
  //               <span className="achievement-circle__title heading-text">
  //               Cost-Effective Solutions
  //               </span>
  //             </div>
  //           </div>
  //           <div className="col-sm-6 col-md-4 col-lg-3">
  //             <div className="achievement-circle text-center">
  //               <div className="mb-4">
  //                 <img
  //                   src="assets/img/achievement-img-4.png"
  //                   alt="image"
  //                   className="img-fluid"
  //                 />
  //               </div>
  //               <span className="achievement-circle__title heading-text">
  //               Proven Track Record
  //               </span>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default CorporateAgencyAchievement;
