import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import ContactFormThree from '../../components/contact/ContactFormThree';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo.js';
import GameServices from '../../components/services/GameServices';
import FeatureImgContentSix from '../../components/feature-img-content/FeatureImgContentSix.js';
import CtaThree from '../../components/cta/CtaThree.js';
import WmsHero from './WmsHero';
import FaqThree from '../../components/faqs/FaqThree.js';
import SoftwareCompanyPricing from '../../components/softwareCompanyHome/SoftwareCompanyPricing.js';
import WorkProcessThree from '../../components/work-process/WorkProcessThree';
import FooterSocial from '../../layout/Footer/FooterSocial';
import NavbarWms from '../../layout/Header/Navbar_Wms';
import Layout from '../../layout/Layout';
import HelpDeskFaq from "../../components/helpDeskHome/HelpDeskFaq.js"

const HomeAppLanding = () => {
  return (
    <Layout>
      <PageMeta title='Wms' />
      <NavbarWms navDark />
      <WmsHero />
      <GameServices />
      <FeatureImgContentSix />
      <CtaThree />
      <WorkProcessThree />
      <SoftwareCompanyPricing />
      <HelpDeskFaq/>
      <FaqThree />
      <TestimonialTwo dark/>
      <ContactFormThree />
      <FooterSocial footerLight />
    </Layout>
  );
};

export default HomeAppLanding;
