import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const FeatureTwo = ({ cardDark }) => {
  return (
    <>
      <section
        className={`feature-section p-50 mt-5 ${
          cardDark ? 'bg-dark' : 'bg-light'
        }`}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              {cardDark ? (
                <SectionTitle
                  subtitle='Services'
                  title='Best Services To Grow Your Business Value'
                  description='Unlock Your Potential with Our Comprehensive Solutions—Success is Just a Step Away!'
                  centerAlign
                  dark
                />
              ) : (
                <SectionTitle
                  subtitle='Services'
                  title='Best Services To Grow Your Business Value'
                  description='Unlock Your Potential with Our Comprehensive Solutions—Success is Just a Step Away!'
                  centerAlign
                />
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='feature-grid'>
                <div
                  className={`feature-card shadow-sm rounded-custom px-5 pt-5 d-flex flex-column justify-content-between featured-card-height ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  {/* <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  > */}
                    {/* <i className='fal fa-file-chart-line icon-sm text-success'></i> */}
                  {/* </div> */}
                  <div className='feature-content'>
                    <h3 className='h5 text-capitalize'>It Consultation</h3>
                    <p className='mb-0'>
                    Don't just follow the latest tech trends. Partner with Senerech to build a custom technology roadmap that's tailored to your business.
                    </p>
                  </div>
                  <Link
                    to='/it-consultation'
                    className='link-with-icon text-decoration-none pb-4'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom px-5 pt-5 d-flex flex-column justify-content-between featured-card-height ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  {/* <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-danger-soft
                      mb-32
                    '
                  > */}
                    {/* <i className='fal fa-user-friends icon-sm text-danger'></i> */}
                  {/* </div> */}
                  <div className='feature-content'>
                    <h3 className='h5 text-capitalize'>App and Web Development</h3>
                    <p className='mb-0'>
                    Development services deliver user-friendly, and responsive solutions, from small websites to large-scale enterprise applications .
                    </p>
                  </div>
                  <Link
                    to='/web-devlopment'
                    className='link-with-icon text-decoration-none pb-4'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom px-5 pt-5 d-flex flex-column justify-content-between featured-card-height ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  {/* <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-dark-soft
                      mb-32
                    '
                  > */}
                    {/* <i className='fal fa-spell-check icon-sm text-dark'></i> */}
                  {/* </div> */}
                  <div className='feature-content'>
                    <h3 className='h5 text-capitalize'>Digital Marketing</h3>
                    <p className='mb-0'>
                    Digital marketing services can help you improve your online visibility, attract targeted traffic, engage with your audience, and drive conversions.
                    </p>
                  </div>
                  <Link
                    to='/digital-marketing'
                    className='link-with-icon text-decoration-none pb-4'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom px-5 pt-5 d-flex flex-column justify-content-between featured-card-height ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  {/* <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-warning-soft
                      mb-32
                    '
                  > */}
                    {/* <i className='fal fa-cog icon-sm text-warning'></i> */}
                  {/* </div> */}
                  <div className='feature-content'>
                    <h3 className='h5'>Graphic Designing</h3>
                    <p className='mb-0'>
                    Graphic design services can help you create a cohesive brand identity that stands out and resonates with your audience.
                    </p>
                  </div>
                  <Link
                    to='/graphic-designing'
                    className='link-with-icon text-decoration-none pb-4'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom px-5 pt-5 d-flex flex-column justify-content-between featured-card-height ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  {/* <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-warning-soft
                      mb-32
                    '
                  > */}
                    {/* <i className='fal fa-cog icon-sm text-warning'></i> */}
                  {/* </div> */}
                  <div className='feature-content'>
                    <h3 className='h5 text-capitalize'>Social media management</h3>
                    <p className='mb-0'>
                    Social media management services can help you create engaging content, build a strong online community and reach a wider audience.
                    </p>
                  </div>
                  <Link
                    to='/social-media-managment'
                    className='link-with-icon text-decoration-none pb-4'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom px-5 pt-5 d-flex flex-column justify-content-between featured-card-height ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  {/* <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-warning-soft
                      mb-32
                    '
                  > */}
                    {/* <i className='fal fa-cog icon-sm text-warning'></i> */}
                  {/* </div> */}
                  <div className='feature-content'>
                    <h3 className='h5 text-capitalize'>Video making </h3>
                    <p className='mb-0'>
                    Our video production services offer comprehensive solutions from concept development to final delivery, ensuring your video is visually stunning, engaging, and aligns with your goals.
                    </p>
                  </div>
                  <Link
                    to='/video-marketing-services'
                    className='link-with-icon text-decoration-none pb-4'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureTwo;
