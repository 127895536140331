import React from "react";
import { Link } from "react-router-dom";

const CryptoPromo = () => {
  return (
    <section className="crypto-promo bg-dark-black pt-120 pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-5 text-capitalize">
              <h2>Senertech is at the forefront of app and web development services </h2>
              <p className="lead text-white">Our skilled developers specialize in crafting tailored solutions to suit your unique requirements. Count on us to deliver custom-built applications that align perfectly with your vision.</p>
              {/* <p className="px-5">
                We have a team of experienced developers who can create custom solutions that meet your specific needs .
              </p> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="bg-soft-black crypto-promo-box mb-30 mb-lg-0">
              <div className="crypt-promo-icon">
                <i className="far fa-chart-network"></i>
              </div>
              <h4 className="fw-medium">Technologies</h4>
              <p>
              We offer a wide range of technologies to conquer business scalability.
              </p>
              {/* <Link to="/about-us">
                Explore More <i className="far fa-arrow-right"></i>
              </Link> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="bg-soft-black crypto-promo-box mb-30 mb-lg-0">
              <div className="crypt-promo-icon">
                <i className="far fa-tags"></i>
              </div>
              <h4 className="fw-medium">Commitment</h4>
              <p>
              We are committed to customer satisfaction at level best.
              </p>
              {/* <Link to="/about-us">
                Explore More <i className="far fa-arrow-right"></i>
              </Link> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="bg-soft-black crypto-promo-box ">
              <div className="crypt-promo-icon">
                <i className="far fa-shield-check"></i>
              </div>
              <h4 className="fw-medium">Expertise</h4>
              <p>
              We are a team of tech enthusiasts’ expertise in specific business needs.
              </p>
              {/* <Link to="/about-us">
                Explore More <i className="far fa-arrow-right"></i>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CryptoPromo;
