import React from 'react';
import { Link } from 'react-router-dom';

const FooterOne = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${footerGradient ? 'bg-gradient' : ''
            }  text-white ptb-40`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                    <img
                      width={200}
                      height={70}
                      src='assets/logo/name-gold-white-tb.png'
                      alt='logo'
                      className='img-fluid logo-white'
                    />
                    <img
                      width={200}
                      height={70}
                      src='assets/logo/name-gold-blue-tb.png'
                      alt='logo'
                      className='img-fluid logo-color'
                    />
                  </div>
                  <p>
                    Our latest news, articles, and resources, we will sent to
                    your inbox weekly.
                  </p>

                  <form className='newsletter-form position-relative d-block d-lg-flex d-md-flex'>
                    <input
                      type='text'
                      className='input-newsletter form-control me-2'
                      placeholder='Enter your email'
                      name='email'
                      required=''
                      autoComplete='off'
                    />
                    <input
                      type='submit'
                      value='Subscribe'
                      data-wait='Please wait...'
                      className='btn btn-primary mt-3 mt-lg-0 mt-md-0'
                    />
                  </form>
                  {/* <a href="/request-demo">
                    <h6 style={{ textDecoration: "underline" }} className='mt-4'>
                      Get Quote
                    </h6>
                  </a> */}
                  <div className='ratting-wrap mt-4'>
                    <h6 className='mb-0'>10/10 Overall rating</h6>
                    <ul className='list-unstyled rating-list list-inline mb-0'>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 memt-md-0 mt-lg-0'>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 mt-4  mt-md-0 mt-lg-0'>
                    <div className='footer-single-col '>
                      <p className="text-uppercase fw-bold">AUTOMOTIVE PRODUCTS</p>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link 
                          to='/wms' className='text-decoration-none fw-light'>
                            WMS
                          </Link>
                        </li>
                        <li>
                          <Link 
                          title='Coming Soon ..'
                          // to='/wms' 
                          className='text-decoration-none fw-light text-muted'>
                            ABMS
                          </Link>
                        </li>
                        {/* <li>
                          <Link to='/about-us' className='text-decoration-none'>
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to='/services' className='text-decoration-none'>
                            Services
                          </Link>
                        </li>
                        <li>
                          <Link to='/career' className='text-decoration-none'>
                            Career
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/integrations'
                            className='text-decoration-none'
                          >
                            Integrations
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/integration-single'
                            className='text-decoration-none'
                          >
                            Integration Single
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <p className='fw-bold'>POPULAR SERVICES</p>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            to='/it-consultation'
                            className='text-decoration-none fw-light'>
                            IT Consultation
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/web-devlopment'
                            className='text-decoration-none fw-light'>
                            Web & App Development
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/digital-marketing'
                            className='text-decoration-none fw-light'
                          >
                            Digital Marketing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/graphic-designing'
                            className='text-decoration-none fw-light'
                          >
                            Graphic Designing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/social-media-managment'
                            className='text-decoration-none fw-light'
                          >
                            Social Media Management
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/video-marketing-services'
                            className='text-decoration-none fw-light'
                          >
                            Video Making
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <p className='fw-bold'>QUICK LINKS</p>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            // to='/contact-us'
                            className='text-decoration-none fw-light'
                          >
                            Career
                          </Link>
                        </li>
                        <li>
                          <Link
                            // to='/help-center'
                            className='text-decoration-none fw-light'
                          >
                            Blog
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/contact-us'
                            className='text-decoration-none fw-light'
                          >
                            Contact
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            // to='/coming-soon'
                            className='text-decoration-none'
                          >
                            Help Center
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link to='/login' className='text-decoration-none'>
                            User Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/career-single'
                            className='text-decoration-none'
                          >
                            Career Single
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${footerLight ? 'footer-light' : 'bg-dark'
            } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    Copyright © Senertech Software Private Limited. All Rights Reserved
                    {/* <Link
                      to='/https://themetags.com/'
                      className='text-decoration-none ms-2'
                    >
                      ThemeTags
                    </Link> */}
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                    <li className='list-inline-item'>
                      <a href='https://www.facebook.com/senertech' target ="_blank">
                        <i className='fab fa-facebook-f'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.instagram.com/sener.tech' target ="_blank">
                        <i className='fab fa-instagram'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.linkedin.com/company/sener-tech'  target ="_blank">
                        <i className='fab fa-linkedin'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://x.com/sener_tech'  target ="_blank">
                        <i className='fa fa-twitter'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.youtube.com/@senertech'  target ="_blank">
                        <i className='fab fa-youtube'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
