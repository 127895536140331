import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Services from "./pages/services/Services";

import ItConsultation from "./pages/services/ItConsultation";
import WebDevlopment from "./pages/services/WebDevlopment";
import GraphicDesgining from "./pages/services/GraphicDesgining";
import SocialMedia from "./pages/services/SocialMedia";
import VideoMaking from "./pages/services/VideoMaking";
import DigitialMarketing from "./pages/services/DigitialMarketing";
import Login from "./pages/onboard/Login";
import NotFoundScreen from "./components/others/NotFoundScreen";
import Aiims from "./themes/Aiims/AiimsSassOne"
import Wms from "./themes/wms/WmsAppLanding";


import Pricing from "./pages/Pricing";
import ScrollToTop from "./components/common/ScrollToTop";

import About from "./pages/About";
import Contact from "./pages/Contact";
import ComingSoon from "./pages/ComingSoon";

import Blogs from "./pages/blog/Blogs";
import SingleBlog from "./pages/blog/SingleBlog";
import Signup from "./pages/onboard/Signup";
import RequestDemo from "./pages/RequestDemo";
import AiimsQuote from "./pages/GetQuoteAiims"

// Swiper CSS
import "swiper/css";
import "swiper/css/navigation";

//bootstrap
import "bootstrap/dist/js/bootstrap.bundle";

//Modal Video
import "react-modal-video/scss/modal-video.scss";
import SoftwareCompany from "./themes/SoftwareCompany";


function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<SoftwareCompany />} />
          <Route path="/aiims" element={<Aiims />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/it-consultation" element={<ItConsultation />} />
          <Route path="/web-devlopment" element={<WebDevlopment/>} />
          <Route path="/digital-marketing" element={<DigitialMarketing />} />
          <Route path="/graphic-designing" element={<GraphicDesgining/>} />
          <Route path="/social-media-managment" element={<SocialMedia />} />
          <Route path="/video-marketing-services" element={<VideoMaking />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog-single" element={<SingleBlog />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/request-demo" element={<RequestDemo />} />
          <Route path="/aiims-quote" element={<AiimsQuote />} />
          <Route path="/wms" element={<Wms />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/404" element={<NotFoundScreen />} />
          <Route path="*" element={<NotFoundScreen />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
