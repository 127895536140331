import React from 'react';
import SectionTitle from '../common/SectionTitle';

const WorkProcessThree = () => {
  return (
    <>
      <section className='process-section bg-light ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='section-heading text-center' data-aos='fade-up'>
                <SectionTitle
                  subtitle='Instant Setup'
                  title='Why to use our Workshop management software'
                  description='“Our workshop software is overflowing with clever features to manage every detail of your workflow for near-limitless productivity and performance.'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='setup-process-wrap'>
                <ul className='setup-process-list list-unstyled text-center mb-0'>
                  <li
                    className='setup-process-item py-4 px-2'
                    data-aos='fade-up'
                  >
                    <div className='setup-process-icon mb-4'>
                      <i className='fal fa-download fa-2x text-primary'></i>
                    </div>
                    <h6 className='mt-3 mb-0'>Quick Setup</h6>
                  </li>
                  <li
                    className='setup-process-item py-4 px-2'
                    data-aos='fade-up'
                    data-aos-delay='50'
                  >
                    <div className='setup-process-icon mb-4'>
                      <i className='fal fa-street-view fa-2x text-primary'></i>
                    </div>
                    <h6 className='mt-3 mb-0'>Instant support</h6>
                  </li>
                  <li
                    className='setup-process-item py-4 px-2'
                    data-aos='fade-up'
                    data-aos-delay='100'
                  >
                    <div className='setup-process-icon mb-4'>
                      <i className='fal fa-bezier-curve fa-2x text-primary'></i>
                    </div>
                    <h6 className='mt-3 mb-0'>Free updates forever</h6>
                  </li>
                  <li
                    className='setup-process-item py-4 px-2'
                    data-aos='fade-up'
                    data-aos-delay='150'
                  >
                    <div className='setup-process-icon mb-4'>
                      <i className='fal fa-user-shield fa-2x text-primary'></i>
                    </div>
                    <h6 className='mt-3 mb-0'>Simple to adopt</h6>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcessThree;
