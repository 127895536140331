import React from 'react';

const FeatureImgContentSix = () => {
  return (
    <>
      <section className='app-two-feature-three bg-light ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-lg-5 col-xl-5 col-sm-12'>
              <div className='app-two-feature-three-left'>
                <div className='app-content-feature-wrap'>
                  <h2>We provide Quality software for your workshop</h2>
                  <p>
                  Workshop Software frees up your time and transforms your business into a streamlined profit machine by managing and optimising your entire workflow. Take back control of your workshop with ease.
                  </p>
                </div>
                <div
                  className='accordion faq-accordion mt-5'
                  id='accordionExample'
                >
                  <div className='accordion-item active border-0'>
                    <h5 className='accordion-header' id='faq-1'>
                      <button
                        className='accordion-button'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapse-1'
                        aria-expanded='true'
                      >
                        <i className='far fa-bell pe-3'></i> How does pricing works ?
                      </button>
                    </h5>
                    <div
                      id='collapse-1'
                      className='accordioncollapse collapse show'
                      aria-labelledby='faq-1'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                      Efficiently set, adjust, and analyze your pricing strategies with real-time insights, enabling you to maximize profits while staying competitive in the market.
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item border-0'>
                    <h5 className='accordion-header' id='faq-2'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapse-2'
                        aria-expanded='false'
                      >
                        <i className='far fa-command pe-3'></i>
                        How does dispute resolution help your business?
                      </button>
                    </h5>
                    <div
                      id='collapse-2'
                      className='accordion-collapse collapse'
                      aria-labelledby='faq-2'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                      Quickly address and resolve customer disputes with a streamlined process that allows for efficient communication and documentation.
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item border-0'>
                    <h5 className='accordion-header' id='faq-3'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapse-3'
                        aria-expanded='false'
                      >
                        <i className='fas fa-link pe-3'></i>
                        How does automated invoicing improve cash flow?
                      </button>
                    </h5>
                    <div
                      id='collapse-3'
                      className='accordion-collapse collapse'
                      aria-labelledby='faq-3'
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>
                      Simplify your billing process with an intuitive invoicing system that allows you to create, send, and track invoices effortlessly.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-xl-6 col-sm-12'>
              <div className='app-two-feature-three-img position-relative text-center mt-5 mt-lg-0'>
                <div className='feature-three-shape'>
                  <img
                    src='assets/img/app-two-feature-blob-shape.png'
                    alt='app screen'
                    className='feature-three-blob img-fluid'
                  />
                </div>
                <img
                  src='assets/img/app-two-feature-phone-without-shape.png'
                  alt='app screen'
                  className='position-relative z-5'
                />
                <div className='img-peice d-none d-lg-block'>
                  <img
                    src='assets/img/app-two-file.png'
                    alt='app screen'
                    className='img-one position-absolute custom-shadow'
                  />
                  <img
                    src='assets/img/app-two-video-shape.png'
                    alt='app screen'
                    className='img-two position-absolute custom-shadow'
                  />
                  <img
                    src='assets/img/app-two-chart.png'
                    alt='app screen'
                    className='img-three position-absolute custom-shadow'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgContentSix;
