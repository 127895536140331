import React from "react";
import { Link } from "react-router-dom";

export default function GameServices() {
  return (
    <section className="game-services ptb-100 mt-50">
      <div className="game-service-bg pt-60 pb-60">
        <div className="container">
          <div className="row  align-items-center">
            <div className="col-lg-5 col-md-8">
              <div className="section-title mb-5 mb-lg-0">
                <h2 className="text-white mb-4">
                Our Special Features
                </h2>
                <p className="mb-2 text-off-white">
                We incorporate many features that can aid in streamlining your business processes, encouraging accountability, providing detailed insights into the actual performance of your workshop and most importantly allowing your business to flourish.
                </p>
                {/* <Link to="/" className="game-btn-mdm mt-4">
                  Explore More
                </Link> */}
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="game-service-right">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row mb--200">
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4">
                          <div className="game-service-icon mb-2">
                            <img src="assets/img/gs1.png" alt="icon" />
                          </div>
                          <h4 className="h5 text-white fw-medium m-0 py-3">
                           Streamlined Workflows
                          </h4>
                          <p className="m-0 text-off-white">
                          Optimize your operations with amazing interface that simplifies every step of your workshop processes, allowing you to focus on what you do best.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4">
                          <div className="game-service-icon mb-2">
                            <img src="assets/img/gs2.png" alt="icon" />
                          </div>
                          <h4 className="h5 text-white fw-medium m-0 py-3">
                          Integrated Solutions
                          </h4>
                          <p className="m-0 text-off-white">
                          Our mobile app seamlessly collaborates with the store portal, streamlining operations and enhancing customer engagement for a superior experience.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row mt--200">
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4">
                          <div className="game-service-icon mb-2">
                            <img src="assets/img/gs2.png" alt="icon" />
                          </div>
                          <h4 className="h5 text-white fw-medium m-0 py-3">
                          GST Compliant
                          </h4>
                          <p className="m-0 text-off-white">
                          Effortlessly handle GST calculations and compliance, save your time and reducing the risk of errors in your financial reporting.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4">
                          <div className="game-service-icon mb-2">
                            <img src="assets/img/gs4.png" alt="icon" />
                          </div>
                          <h4 className="h5 text-white fw-medium m-0 py-3">
                            Fast Game Servers
                          </h4>
                          <p className="m-0 text-off-white">
                            Give your Website the speed, deserves and become
                            part of the fastest Web Hosting{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
