import React from "react";

const CryptoCustomerCounter = () => {
  return (
    <section className="crypto-customer-counter bg-dark-black ptb-60">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="crypto-customer-counter">
              <div className="counter-content section-title mb-30">
                <h2 className="mb-4 ">
                We proudly serve clients from all corners of the world, bringing our solutions to you, no matter where you are
                </h2>
                <p>
                Over 150,000 people have already chosen our app and web development services. They've experienced the power of our innovative solutions and joined our thriving community of satisfied users.
                </p>
              </div>
              <div className="counter-box mb-5 mb-lg-0">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <div className="single-counter-box bg-soft-black mb-3 mb-md-0">
                      <h5 className="h4">1000+</h5>
                      <span>Projects completed </span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="single-counter-box bg-soft-black mb-3 mb-md-0">
                      <h5 className="h4">100k+</h5>
                      <span>Users</span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="single-counter-box bg-soft-black">
                      <h5 className="h4">100%</h5>
                      <span>Uptime guarantee</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="maps-img text-lg-center">
              <img src="assets/img/maps.png" className="img-fluid" alt="Maps" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CryptoCustomerCounter;
