import React from "react";
import { Link } from "react-router-dom";

import SectionTitle from "../common/SectionTitle";

const FaqThree = () => {
  return (
    <>
      <section className="faq-section ptb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <SectionTitle
                subtitle="FAQ"
                title="Frequently Asked Questions"
                description="Efficiently network cross-unit paradigms for premier
                  technologies scale 24/7 paradigms for process-centric data
                  interoperable."
                centerAlign
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">
              <div
                className="faq-content-wrap d-flex mb-5"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <span className="faq-icon me-3">
                  <i className="fal fa-copyright text-primary"></i>
                </span>
                <div className="faq-info">
                  <h5>
                  How does this workshop management software improve efficiency in my workshop?
                  </h5>
                  <p className="mb-0">
                  This software centralizes all essential functions in one platform, reducing the time spent on manual tasks and allowing you to focus on fixing vehicles and serving customers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div
                className="faq-content-wrap d-flex mb-5"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <span className="faq-icon me-3">
                  <i className="fal fa-credit-card text-primary"></i>
                </span>
                <div className="faq-info">
                  <h5>Is there any software to install?</h5>
                  <p className="mb-0">
                  No, it operates entirely in your web browser, so there’s no need for installations or hardware maintenance. Just log in and get started!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div
                className="faq-content-wrap d-flex mb-5"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <span className="faq-icon me-3">
                  <i className="fal fa-clipboard-list-check text-primary"></i>
                </span>
                <div className="faq-info">
                  <h5>What kind of vehicle information can I track?</h5>
                  <p className="mb-0">
                  You can track service history, maintenance schedules, repairs, and customer details for each vehicle, ensuring you stay organized and on top of maintenance needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div
                className="faq-content-wrap d-flex mb-5"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <span className="faq-icon me-3">
                  <i className="fal fa-microphone text-primary"></i>
                </span>
                <div className="faq-info">
                  <h5>Is this workshop management software suitable for small workshops?</h5>
                  <p className="mb-0">
                  Yes! it is designed for workshops of all sizes, providing scalable solutions that grow with your business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div
                className="faq-content-wrap d-flex mb-5"
                data-aos="fade-up"
                data-aos-delay="250"
              >
                <span className="faq-icon me-3">
                  <i className="fal fa-play-circle text-primary"></i>
                </span>
                <div className="faq-info">
                  <h5>How do I handle disputes with customers?</h5>
                  <p className="mb-0">
                  Our dispute rectification feature simplifies the process, allowing you to document and communicate issues clearly, fostering transparency and trust with your customers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div
                className="faq-content-wrap d-flex mb-5"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <span className="faq-icon me-3">
                  <i className="fal fa-code-branch text-primary"></i>
                </span>
                <div className="faq-info">
                  <h5>What support do you offer?</h5>
                  <p className="mb-0">
                  We provide comprehensive customer support via email and live chat, along with a knowledge base filled with tutorials and guides to help you get the most out of this software.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="bg-dark shadow-lg p-5 rounded-custom  mt-lg-4 mt-3"
            data-aos="fade-up"
            data-aos-delay="250"
          >
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-7">
                <div className="cta-content">
                  <h3 className="mb-2">Have More Questions?</h3>
                  <p className="mb-lg-0 mb-xl-0">
                    Drop us a note and we’ll get back to you soon
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="action-btns text-lg-end">
                  <Link 
                  // to="/contact-us" 
                  className="btn btn-secondary">
                    Mail Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqThree;
