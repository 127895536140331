import React from "react";
import PageHeader from "../../components/common/PageHeader";
// import CtaTwo from "../../components/cta/CtaTwo";
import FeatureTwo from "../../components/features/FeatureTwo";
import TestimonialTwo from "../../components/testimonial/TestimonialTwo";
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";

const Services = () => {
  return (
    <Layout>
      <Navbar navDark />
      <PageHeader
        title="Transforming Ideas into Impactful Solutions"
        desc="
          Deliver tailored services that empower users and drive measurable value. Harness strategic data with our seamless expertise to fuel innovation and growth.        "
      />
      <FeatureTwo />
      <TestimonialTwo bgWhite />
      {/* <CtaTwo /> */}
      <FooterOne footerLight />
    </Layout>
  );
};

export default Services;
