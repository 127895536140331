import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from "../common/SectionTitle"
const FeatureImgContentFour = () => {
  return (
    <>
      <section className='why-choose-us '>
        <div className='container mt-100'>
        <SectionTitle
                title='Products'

                centerAlign
              />
          <div className='row justify-content-lg-between align-items-center mt-0'>
            <div className='col-lg-5 col-12 pt-0'>
              <div className='why-choose-content'>
                <div className='icon-box rounded-custom bg-primary shadow-sm d-inline-block'>
                  <i className='fal fa-shield-check fa-2x text-white'></i>
                </div>
                <h2>Workshop management software</h2>
                <p>
                Effortlessly manage and optimize your entire workflow, allowing you to focus on what truly matters—growing your business and enhancing customer satisfaction.
                </p>
                <ul className='list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4'>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    Seamless Tracking
                    </li>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    Data Organization
                    </li>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    Optimal Performance
                    </li>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    User-Friendly Platform
                    </li>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    Workflow Optimization
                    </li>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    Customer Focus
                    </li>
                </ul>
                <Link
                  to="/wms"
                  className='read-more-link text-decoration-none'
                >
                  Know More About Us <i className='far fa-arrow-right ms-2'></i>
                </Link>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='feature-img-holder  mt-lg-0 mt-xl-0'>
                <img
                  src='assets/img/screen/widget-11.png'
                  className='img-fluid'
                  alt='feature-img'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgContentFour;
