import React from 'react';
import { Link } from 'react-router-dom';

import HeroTitle from '../../components/common/HeroTitle';

const WmsHero = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 text-white bg-dark'
        style={{
          background: "url('assets/img/hero-dot-bg.png')no-repeat center right",
        }}
      >
        <div className='container'>
          <div className='row justify-content-center text-center text-lg-start align-items-center'>
            <div className='col-lg-6 col-md-10'>
              <div className='hero-content-wrap mt-5 mt-lg-0 mt-xl-0'>
                <HeroTitle
                  title='Focus on Fixing Cars, Not Computers
                          Your Garage Deserves Top-Notch Software'
                  desc='Our workshop management software runs in your web browser, so it’s compatible with the devices you already have. No installations, no hardware hassles.'
                />

                <div
                  className='action-btns mt-5'
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <Link 
                  // to='/request-for-demo' 
                  className='btn btn-primary me-3'>
                    Request Demo
                  </Link>
                </div>
                <ul
                  className='nav subscribe-feature-list d-flex justify-content-center justify-content-lg-start w-100 mt-3'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >
                  <li className='nav-item'>
                    <span className='ms-0'>
                      <i className='far fa-check-circle text-primary me-2'></i>
                      Free 14-day trial
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-8'>
              <div
                className='hero-img-wrap position-relative app-screen-bg mt-5'
                style={{
                  backgroundImage: 'url(assets/img/shape/shape-bg-3.svg)',
                }}
                data-aos='fade-up'
                data-aos-delay='200'
              >   
                <img
                  src='assets/img/screen/app-screen-on-hand.png'
                  alt='hero'
                  className='img-fluid position-relative z-5'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WmsHero;
