import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgThree = ({paddingTop}) => {
  return (
    <>
      <section className={`feature-section-two ${paddingTop?'ptb-120':'pt-50'} `}>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12'>
              <SectionTitle
                subtitle='Features'
                // title='Powerful Solutions for Your Business Needs'
                // description='Quickly incubate functional channels with multidisciplinary
                //   architectures authoritatively fabricate.'
              />
              <div>
                <ul className='list-unstyled mt-5'>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-primary rounded me-4'>
                      <i className='fas fa-bezier-curve text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Solve your business problems with tech</h3>
                      <p>
                      We'll craft custom tech solutions that perfectly fit your business needs. Our team of experts will work closely with you to understand your challenges that drive your success.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-warning rounded me-4'>
                    <i class="fa-thin fa-magnifying-glass"></i>
                                       </div>
                    <div className='icon-content'>
                      <h3 className='h5'>User-friendly tech that works for you</h3>
                      <p>
                      Our commitment to quality extends to every aspect of our work, including the design and user experience. We create stunning interfaces that are easy to navigate and a pleasure to use.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-danger rounded me-4'>
                      <i className='fas fa-fingerprint text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Simplify your workday with smart tech</h3>
                      <p>
                      Our tech solutions are designed to simplify your operations, reduce costs, and improve efficiency, so you can stay ahead of the competition.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap position-relative d-flex flex-column align-items-end'>
                {/* <ul className='img-overlay-list list-unstyled position-absolute'>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='50'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Create a Free Account</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='100'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Install Our Tracking Pixel</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='150'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Start Tracking your Website</h6>
                  </li>
                </ul> */}
                <img
                  src='assets/img/feature-img3.jpg'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgThree;
