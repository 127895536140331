import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Terms from "./terms"

const InquiryForm = () => {
  const [openTerms,setOpenTerms] =useState(false)
  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            Fill out the form and we'll be in touch as soon as possible.
          </h3>

          <form action='#' className='mt-4 register-form'>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Name'
                    aria-label='name'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Website'
                    aria-label='Website'
                  />
                </div>
              </div>
              <div className='col-sm-12 d-flex'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Email'
                    aria-label='Email'
                  />
                </div>
                <a className='btn btn-primary mb-3 ms-1'>Verify</a>
              </div>
              <div className='col-sm-12 d-flex'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Mobile'
                    aria-label='Mobile'
                  />
                </div>
                <a className='btn btn-primary mb-3 ms-1'>Verify</a>

              </div>
              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <select
                    className='form-control form-select'
                    name='budget'
                    id='budget'
                    required=''
                    data-msg='Please select your budget.'
                  >
                    <option value=''>Service </option>
                    <option value='budget1'> IT Consultation</option>
                    <option value='budget1'>Web & App Development</option>
                    <option value='budget1'> Digital Marketing</option>
                    <option value='budget1'> Graphic Designing</option>
                    <option value='budget2'>Social Media Management</option>
                    <option value='budget3'>Video Making </option>
                  </select>
                </div>
              </div>
             
              <div className='col-12'>
                <div className='input-group mb-3'>
                  <textarea
                    className='form-control'
                    placeholder='Tell us about your project, requirement and budget'
                    style={{ height: '120px' }}
                  ></textarea>
                </div>
              </div>
              <div className='col-12'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckChecked'
                  />
                  <label
                    className='form-check-label small'
                    htmlFor='flexCheckChecked'
                  >
                    Yes, I'd like to receive occasional marketing emails from
                    us. I have the right to opt out at any time.
                    <Link to='#' onClick={()=>setOpenTerms(true)}> View privacy policy</Link>.
                  </label>
                </div>
              </div>
              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary mt-4 d-block w-100'
                >
                  Get Started
                </button>
              </div>
            </div>
          </form>
        </div>
        <Terms
      show = {openTerms}
      onHide ={()=>setOpenTerms(false)}/>
      </div>
     
    </>
  );
};

export default InquiryForm;
