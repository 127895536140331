import React from 'react';

export default function DigiWhyChoose() {
  return (
    <section className="digi-why pt-50">
      <div className="container">
        <div className="row align-content-center justify-content-between">
          <div className="col-lg-6 col-md-12">
            <div className="digi-why-left">
              <img
                src="assets/img/d-girl-w-p.png"
                className="img-fluid"
                alt="Girl"
              />
              <ul className="list-unstyled d-none d-md-block">
                <li>
                  <img
                    src="assets/img/dg-shape-1.png"
                    className="shadow img-fluid"
                    alt="shape"
                  />
                </li>
                <li>
                  <img
                    src="assets/img/dg-shape-2.png"
                    className="shadow img-fluid"
                    alt="shape"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="digi-why-right pt-4">
              <div>
                <span className="span-arrow">Why Choose Us</span>
                <img
                  src="assets/img/arro-right.svg"
                  className="img-fluid"
                  alt="arrow"
                />
              </div>
              <h2>Why Working With Us?</h2>
              <p>
              Senertech is your trusted partner for effective and engaging social media management. Our team of experts will help you leverage the power of social media to connect with your target audience, build brand awareness, and drive business growth.
              </p>
              <div>
                <ul className="list-unstyled mb-0 mt-5">
                  <li className="d-flex p-3 align-items-center">
                    <div className="me-3">
                      <img src="assets/img/icons/dw-i-1.svg" alt="icon" />
                    </div>
                    <div>
                      <h4 className="mb-0">100k+</h4>
                      <span>Happy Customers</span>
                    </div>
                  </li>
                  <li className="pe-4 d-flex p-3 align-items-center">
                    <div className="me-3">
                      <img
                        src="assets/img/icons/dw-i-2.svg"
                        className="img-fluid"
                        alt="icon"
                      />
                    </div>
                    <div>
                      <h4 className="mb-0">1000+</h4>
                      <span>Projects </span>
                    </div>
                  </li>
                  <li className="pe-4 d-flex p-3 align-items-center">
                    <div className="me-3">
                      <img
                        src="assets/img/icons/dw-i-3.svg"
                        className="img-fluid"
                        alt="icon"
                      />
                    </div>
                    <div>
                      <h4 className="mb-0">100+</h4>
                      <span> Team Members</span>
                    </div>
                  </li>
                  <li className="pe-4 d-flex p-3 align-items-center">
                    <div className="me-3">
                      <img
                        src="assets/img/icons/dw-i-4.svg"
                        className="img-fluid"
                        alt="icon"
                      />
                    </div>
                    <div>
                      <h4 className="mb-0">100%</h4>
                      <span>Satisfaction </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
