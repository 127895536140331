import React from "react";

const serviceData = [
  {
    id: 1,
    title: "Industry Expertise",
    description:
      "Our consultants have experience in various industries, enabling them to provide insightful recommendations tailored to your specific needs.",
    imgSrc: "assets/img/service-icon-1.png",
  },
  {
    id: 2,
    title: "Customized Solutions",
    description:
      " We believe in a personalized approach. Our consultants will work closely with you to understand your business objectives aligns with your goals.",
    imgSrc: "assets/img/service-icon-2.png",
  },
  {
    id: 3,
    title: "Proven Track Record",
    description:
      "Senertech has a proven track record of delivering successful IT projects. We're your reliable partner for expert consulting services. ",
    imgSrc: "assets/img/service-icon-3.png",
  },
  {
    id: 4,
    title: "Affordable Solutions",
    description:
      "We prioritize efficiency and cost-effectiveness, ensuring you get the most value from your IT investments. ",
    imgSrc: "assets/img/service-icon-1.png",
  },
  {
    id: 5,
    title: "Guranteed Excellence",
    description:
      "Senertech is dedicated to providing exceptional service and exceeding your expectations. Our team of experts is passionate about helping you succeed.",
    imgSrc: "assets/img/service-icon-2.png",
  },
  {
    id: 6,
    title: "Comprehensive IT Services",
    description:
      " In addition to expert consultation, we offer a wide range of IT services to support your business needs, including support, and ongoing maintenance.",
    imgSrc: "assets/img/service-icon-3.png",
  },
];

const CorporateAgencyService = () => {
  return (
    <div className="container pt-50">
      <div className="row">
        <div className="col-12">
          <div className="service-section sections rounded-4">
            <div className="sections__head">
              <div className="row g-4 align-items-center">
                <div className="col-md-8">
                  <div className="d-flex align-items-center gap-1 mb-4">
                    <span className="service-section__subtitle d-inline-block fw-semibold">
                    Our Expertise
                    </span>
                    <span className="d-inline-block flex-shrink-0">
                      <img
                        src="assets/img/icon-arrow-curve.png"
                        alt="Our Services"
                        className="img-fluid"
                      />
                    </span>
                  </div>
                  <h2 className="mb-0">
                    A collection of sales tools designed with revenue growth in
                    mind
                  </h2>
                </div>
                <div className="col-md-4">
                  <div className="text-center text-md-end">
                    <img
                      src="assets/img/service-section-img.png"
                      alt="Service Section"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-4">
              {serviceData.map((service) => (
                <div className="col-md-6 col-lg-4" key={service.id}>
                  <div className="card border-0 service-card" style={{height:"285px"}}>
                    <div className="card-body">
                      {/* <div className="mb-32">
                        <img
                          src={service.imgSrc}
                          alt={service.title}
                          className="img-fluid"
                        />
                      </div> */}
                      <h6 className="service-card__title heading-text">
                        {service.title}
                      </h6>
                      <p className="mb-0">{service.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateAgencyService;
