import { Link } from "react-router-dom";

const AiContentGeneratorWork = () => {
  return (
    <>
      <div className="ail-work-area pt-50 pb-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <div className="text-center mb-30">
                <p className="ail-sub-title text-black fw-500 ah-input-bg d-inline-flex align-items-center gap-2 rounded-5 mb-20">
                  <span className="ail-gd-bg"></span> How it works
                </p>
                <h2 className="ail-title text-black fs-48 fw-600">
                Graphic designing is a creative process that involves {" "}
                  <span className="ail-highlighted-text">
                  transforming ideas into visual representations
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="ail-step-sub-title d-flex align-items-center gap-3 mb-20">
                <span className="bg-black"></span>
                <p className="ca-two-body-clr ff-poppins fw-600 mb-0">
                  Step 01
                </p>
              </div>
              <h3 className="text-black fs-36">
                Briefing and Planning
              </h3>
              {/* <p className="mb-20">
                Marve has the answer to every request and is using the latest
                Google Data for accurate responses.
              </p> */}
              <ul className="ail-work-list list-unstyled">
                <li className="d-flex gap-3">
                  <svg
                    className="mt-5-ov"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M8.9998 1.80005C5.0308 1.80005 1.7998 5.03105 1.7998 9.00005C1.7998 12.969 5.0308 16.2 8.9998 16.2C12.9688 16.2 16.1998 12.969 16.1998 9.00005C16.1998 5.03105 12.9688 1.80005 8.9998 1.80005ZM7.1998 13.5L3.5998 9.90005L4.8688 8.63105L7.1998 10.953L13.1308 5.02205L14.3998 6.30005L7.1998 13.5Z"
                      fill="#6672FB"
                    />
                    <path
                      d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM13.131 5.022L7.2 10.953L4.869 8.631L3.6 9.9L7.2 13.5L14.4 6.3L13.131 5.022Z"
                      fill="#476EFB"
                    />
                  </svg>
                  <div className="ail-work__content">
                    <p className="text-black fw-700 mb-0">
                    Understanding the Client's Needs
                    </p>
                    <p className="fch-40 mb-0">
                    The designer listens attentively to the client's requirements, goals, and target audience. 

                    </p>
                  </div>
                </li>
                <li className="d-flex gap-3 mt-20">
                  <svg
                    className="mt-5-ov"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M8.9998 1.80005C5.0308 1.80005 1.7998 5.03105 1.7998 9.00005C1.7998 12.969 5.0308 16.2 8.9998 16.2C12.9688 16.2 16.1998 12.969 16.1998 9.00005C16.1998 5.03105 12.9688 1.80005 8.9998 1.80005ZM7.1998 13.5L3.5998 9.90005L4.8688 8.63105L7.1998 10.953L13.1308 5.02205L14.3998 6.30005L7.1998 13.5Z"
                      fill="#6672FB"
                    />
                    <path
                      d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM13.131 5.022L7.2 10.953L4.869 8.631L3.6 9.9L7.2 13.5L14.4 6.3L13.131 5.022Z"
                      fill="#476EFB"
                    />
                  </svg>
                  <div className="ail-work__content">
                    <p className="text-black fw-700 mb-0">
                    Research and Analysis
                    </p>
                    <p className="fch-40 mb-0">
                    The designer conducts thorough research on the client's industry, competitors, and target market to gather relevant information. 
                    </p>
                  </div>
                </li>
                <li className="d-flex gap-3 mt-20">
                  <svg
                    className="mt-5-ov"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M8.9998 1.80005C5.0308 1.80005 1.7998 5.03105 1.7998 9.00005C1.7998 12.969 5.0308 16.2 8.9998 16.2C12.9688 16.2 16.1998 12.969 16.1998 9.00005C16.1998 5.03105 12.9688 1.80005 8.9998 1.80005ZM7.1998 13.5L3.5998 9.90005L4.8688 8.63105L7.1998 10.953L13.1308 5.02205L14.3998 6.30005L7.1998 13.5Z"
                      fill="#6672FB"
                    />
                    <path
                      d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM13.131 5.022L7.2 10.953L4.869 8.631L3.6 9.9L7.2 13.5L14.4 6.3L13.131 5.022Z"
                      fill="#476EFB"
                    />
                  </svg>
                  <div className="ail-work__content">
                    <p className="text-black fw-700 mb-0">
                    Creating a Design Brief
                    </p>
                    <p className="fch-40 mb-0">
                    A clear and concise design brief is developed, outlining the project's scope, objectives, and key elements.                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="ail-gd-bg-2 p-4 pt-40 pb-40 rounded-16">
                <img
                  src="assets/img/ail_home/work-1.png"
                  alt=""
                  className="w-100 img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="mt-50">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="ail-gd-bg-3 p-4 pt-40 pb-40 rounded-16">
                  <img
                    src="assets/img/ail_home/work-2.png"
                    alt=""
                    className="w-100 img-fluid rounded-16"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="pl-40">
                  <div className="ail-step-sub-title d-flex align-items-center gap-3 mb-20">
                    <span className="bg-black"></span>
                    <p className="ca-two-body-clr ff-poppins fw-600 mb-0">
                      Step 02
                    </p>
                  </div>
                  <h3 className="text-black fs-36">
                  Design and Development
                  </h3>
                  {/* <p className="mb-20">
                    Marve has the answer to every request and is using the
                    latest Google Data for accurate responses.
                  </p> */}
                <ul className="ail-work-list list-unstyled">
                <li className="d-flex gap-3">
                  <svg
                    className="mt-5-ov"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M8.9998 1.80005C5.0308 1.80005 1.7998 5.03105 1.7998 9.00005C1.7998 12.969 5.0308 16.2 8.9998 16.2C12.9688 16.2 16.1998 12.969 16.1998 9.00005C16.1998 5.03105 12.9688 1.80005 8.9998 1.80005ZM7.1998 13.5L3.5998 9.90005L4.8688 8.63105L7.1998 10.953L13.1308 5.02205L14.3998 6.30005L7.1998 13.5Z"
                      fill="#6672FB"
                    />
                    <path
                      d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM13.131 5.022L7.2 10.953L4.869 8.631L3.6 9.9L7.2 13.5L14.4 6.3L13.131 5.022Z"
                      fill="#476EFB"
                    />
                  </svg>
                  <div className="ail-work__content">
                    <p className="text-black fw-700 mb-0">
                    Concept Generation
                    </p>
                    <p className="fch-40 mb-0">
                    The designer explores various design concepts, considering the client's preferences, brand identity, and target audience.
                    </p>
                  </div>
                </li>
                <li className="d-flex gap-3 mt-20">
                  <svg
                    className="mt-5-ov"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M8.9998 1.80005C5.0308 1.80005 1.7998 5.03105 1.7998 9.00005C1.7998 12.969 5.0308 16.2 8.9998 16.2C12.9688 16.2 16.1998 12.969 16.1998 9.00005C16.1998 5.03105 12.9688 1.80005 8.9998 1.80005ZM7.1998 13.5L3.5998 9.90005L4.8688 8.63105L7.1998 10.953L13.1308 5.02205L14.3998 6.30005L7.1998 13.5Z"
                      fill="#6672FB"
                    />
                    <path
                      d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM13.131 5.022L7.2 10.953L4.869 8.631L3.6 9.9L7.2 13.5L14.4 6.3L13.131 5.022Z"
                      fill="#476EFB"
                    />
                  </svg>
                  <div className="ail-work__content">
                    <p className="text-black fw-700 mb-0">
                    Sketching and Wireframing
                    </p>
                    <p className="fch-40 mb-0">
                    Initial sketches or wireframes are created to visualize the layout and structure of the design.                    </p>
                  </div>
                </li>
                <li className="d-flex gap-3 mt-20">
                  <svg
                    className="mt-5-ov"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M8.9998 1.80005C5.0308 1.80005 1.7998 5.03105 1.7998 9.00005C1.7998 12.969 5.0308 16.2 8.9998 16.2C12.9688 16.2 16.1998 12.969 16.1998 9.00005C16.1998 5.03105 12.9688 1.80005 8.9998 1.80005ZM7.1998 13.5L3.5998 9.90005L4.8688 8.63105L7.1998 10.953L13.1308 5.02205L14.3998 6.30005L7.1998 13.5Z"
                      fill="#6672FB"
                    />
                    <path
                      d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM13.131 5.022L7.2 10.953L4.869 8.631L3.6 9.9L7.2 13.5L14.4 6.3L13.131 5.022Z"
                      fill="#476EFB"
                    />
                  </svg>
                  <div className="ail-work__content">
                    <p className="text-black fw-700 mb-0">
                    Design Development
                    </p>
                    <p className="fch-40 mb-0">
                    The selected concept is refined and developed into a visually appealing and functional design, incorporating typography, color schemes, and imagery.
                      </p>                   
                  </div>
                </li>
              </ul >
                </div>
              </div>
            </div>
          </div>
          <div className="mt-40">
            <div className="ail-main-bg p-5 rounded-16">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="ail-step-sub-title d-flex align-items-center gap-3 mb-20">
                    <span className="bg-black"></span>
                    <p className="ca-two-body-clr ff-poppins fw-600 mb-0">
                      Final Step
                    </p>
                  </div>
                  <h3 className="text-black fs-36">
                  Revisions and Finalization
                  </h3>
                  <ul className="ail-work-list list-unstyled">
                <li className="d-flex gap-3">
                  <svg
                    className="mt-5-ov"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M8.9998 1.80005C5.0308 1.80005 1.7998 5.03105 1.7998 9.00005C1.7998 12.969 5.0308 16.2 8.9998 16.2C12.9688 16.2 16.1998 12.969 16.1998 9.00005C16.1998 5.03105 12.9688 1.80005 8.9998 1.80005ZM7.1998 13.5L3.5998 9.90005L4.8688 8.63105L7.1998 10.953L13.1308 5.02205L14.3998 6.30005L7.1998 13.5Z"
                      fill="#6672FB"
                    />
                    <path
                      d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM13.131 5.022L7.2 10.953L4.869 8.631L3.6 9.9L7.2 13.5L14.4 6.3L13.131 5.022Z"
                      fill="#476EFB"
                    />
                  </svg>
                  <div className="ail-work__content">
                    <p className="text-black fw-700 mb-0">
                    Client Feedback                    </p>
                    <p className="fch-40 mb-0">
                    The designer presents the initial design to the client and incorporates their feedback and suggestions.                     </p>
                  </div>
                </li>
                <li className="d-flex gap-3 mt-20">
                  <svg
                    className="mt-5-ov"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M8.9998 1.80005C5.0308 1.80005 1.7998 5.03105 1.7998 9.00005C1.7998 12.969 5.0308 16.2 8.9998 16.2C12.9688 16.2 16.1998 12.969 16.1998 9.00005C16.1998 5.03105 12.9688 1.80005 8.9998 1.80005ZM7.1998 13.5L3.5998 9.90005L4.8688 8.63105L7.1998 10.953L13.1308 5.02205L14.3998 6.30005L7.1998 13.5Z"
                      fill="#6672FB"
                    />
                    <path
                      d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM13.131 5.022L7.2 10.953L4.869 8.631L3.6 9.9L7.2 13.5L14.4 6.3L13.131 5.022Z"
                      fill="#476EFB"
                    />
                  </svg>
                  <div className="ail-work__content">
                    <p className="text-black fw-700 mb-0">
                    Revisions and Refinements
                    </p>
                    <p className="fch-40 mb-0">
                    The design is revised and refined based on the client's input, ensuring it meets their expectations.  
                    </p>                 
                    </div>
                </li>
                <li className="d-flex gap-3 mt-20">
                  <svg
                    className="mt-5-ov"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M8.9998 1.80005C5.0308 1.80005 1.7998 5.03105 1.7998 9.00005C1.7998 12.969 5.0308 16.2 8.9998 16.2C12.9688 16.2 16.1998 12.969 16.1998 9.00005C16.1998 5.03105 12.9688 1.80005 8.9998 1.80005ZM7.1998 13.5L3.5998 9.90005L4.8688 8.63105L7.1998 10.953L13.1308 5.02205L14.3998 6.30005L7.1998 13.5Z"
                      fill="#6672FB"
                    />
                    <path
                      d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM13.131 5.022L7.2 10.953L4.869 8.631L3.6 9.9L7.2 13.5L14.4 6.3L13.131 5.022Z"
                      fill="#476EFB"
                    />
                  </svg>
                  <div className="ail-work__content">
                    <p className="text-black fw-700 mb-0">
                    Finalization
                    </p>
                    <p className="fch-40 mb-0">
                    Once the client is satisfied with the design, it is finalized and prepared for production or delivery.                      </p>                   
                  </div>
                </li>
              </ul >
                </div>
                <div className="col-lg-6">
                  <div className="pl-15">
                    <div className="bg-sky-blue p-4 rounded-16">
                      <img
                        src="assets/img/ail_home/work-3.png"
                        alt=""
                        className="w-100 img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AiContentGeneratorWork;
