import React from "react";
import { Link } from "react-router-dom";
import {
  navServicesLinks,
  navProductLinks,
} from "./../../data";

const OffCanvasMenuAiims = () => {
  return (
    <div className="offcanvas-body">
      <ul className="nav col-12 col-md-auto justify-content-center main-menu">
      <li className="nav-item dropdown" aria-label="Close">
          <Link legacyBehavior to="/aiims">
            <a className="nav-link">Home</a>
          </Link>
        </li>
        <li className="nav-item dropdown" aria-label="Close">
          <Link legacyBehavior to="/wms">
            <a className="nav-link">Wms</a>
          </Link>
        </li>
      </ul>
      <div className="action-btns mt-4 ps-3">
        <span data-bs-dismiss="offcanvas" aria-label="Close">
          <Link legacyBehavior to="/aiims-quote">
            <a className="btn btn-primary">Get Quote</a>
          </Link>
        </span>
      </div>
    </div>
  );
};

export default OffCanvasMenuAiims;
