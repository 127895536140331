const MarketplaceAgencyCard = () => {
  const cardData = [
    {
      id: 1,
      image: "assets/img/home_37/why-card-1.png",
      title: "Best Digital Marketing ",
      description: "We offer a wide range of services, including SEO, PPC, social media marketing, content marketing, email marketing, and more ."},
    {
      id: 2,
      image: "assets/img/home_37/why-card-2.png",
      title: "Data Driven Approach",
      description: "Our strategies are backed by data-driven insights, ensuring that your marketing efforts are targeted."
    },
    {
      id: 3,
      image: "assets/img/home_37/why-card-3.png",
      title: "Customized Solutions",
      description:
       " We believe in a personalized approach. Our team will work closely with you and develop a customized digital marketing plan for your goals."
    },
    {
      id: 4,
      image: "assets/img/home_37/why-card-4.png",
      title: "Experienced Team",
      description:
        "Our team of experts has a proven track record of delivering successful digital marketing campaigns."
    },
    {
      id: 5,
      image: "assets/img/home_37/why-card-5.png",
      title: "Measurable Results",
      description:
        "We focus on delivering measurable results that drive business growth.",
    },
    {
      id: 6,
      image: "assets/img/home_37/why-card-6.png",
      title: "Commitment to Excellence",
      description:
        "Senertech is dedicated to providing exceptional service and exceeding your expectations."
    },
  ];
  return (
    <>
      <section className="bg-light-subtle pt-50 pb-50">
        <div className="container">
          <div className="row g-4">
            <div className="col-xl-5">
              <div className="position-relative h-100">
                <div className="ca-card-title">
                  <div className="d-inline-block px-4 py-1 rounded-pill border border-warning mb-3">
                    <p className="ma-warning-text fw-bold mb-0">Why Us</p>
                  </div>
                  <h2 className="ail-title text-black fs-48 fw-600 mb-4">
                    Innovating to Empower Businesses
                  </h2>
                  <p className="mb-4">
                  We provide tailored solutions that align with your business objectives and goals. From design to development to launch, we are your on-demand partner.
                  </p>
                  {/* <div className="bg-white px-3 py-2 rounded-pill shadow d-inline-flex align-items-center gap-2">
                    <img
                      src="assets/img/home_37/why-user.png"
                      alt="image"
                      className="img-fluid"
                    />
                    <p className="mb-0">
                      <span className="text-primary fw-semibold">
                        +20k Active{" "}
                      </span>{" "}
                      Users Connected
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-7">
              <div className="row g-4">
                {cardData.map((card) => (
                  <div key={card.id} className="col-md-6">
                    <div className="bg-white px-4 py-4 rounded shadow-sm"  style={{height:"300px"}}>
                      <img
                        src={card.image}
                        alt="image"
                        className="img-fluid mb-3"
                      />
                      <h6 className="heading-6 clr-text font-weight-bold mb-3">
                        {card.title}
                      </h6>
                      <p className="mb-0">{card.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MarketplaceAgencyCard;
